<template>
  <main class="wrapper browser-page">
    <!-- 数据面板 -->
    <section class="header">
      <div class="item">
        <div class="title">{{ header.hashrate }}</div>
        <div class="value">
          {{
            $gbUtils.formatValue(
              $gbUtils.formatBalance(dashBoard.totalHashrate) || 0
            ) || 0
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ header.commission }}</div>
        <div class="value">
          {{
            $gbUtils.formatValue(
              $gbUtils.formatBalance(dashBoard.totalCommission) || 0
            ) || 0
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ header.airdrop }}</div>
        <div class="value">
          {{
            $gbUtils.formatNumber(
              $gbUtils.formatThreeBalance(dashBoard.totalAirDrop) || 0
            ) || 0
          }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ header.price }}</div>
        <div class="value">{{ dashBoard.mebPrice || 0 }}</div>
        <div class="percent">{{ dashBoard.increaseRatio || 0 }}%</div>
      </div>
      <div class="item">
        <div class="title">{{ header.users }}</div>
        <div class="value">{{ dashBoard.userCountActivated || 0 }}</div>
      </div>
      <div class="item">
        <div class="title">{{ header.newUsers }}</div>
        <div class="value">{{ dashBoard.userCountIncreaseToday || 0 }}</div>
      </div>
    </section>
    <!-- 空投 -->
    <section class="airdrop">
      <div class="item">
        <div class="title">{{ airdrop.time }}&nbsp;</div>
        <div class="value">
          {{ $moment(dashBoard.lastAirdropTime).format('MM-DD hh:mm:ss') }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ airdrop.count }}&nbsp;&nbsp;</div>
        <div class="value">
          {{
            $gbUtils.formatNumber(
              $gbUtils.formatThreeBalance(dashBoard.lastAirdropAmount) || 0
            ) || 0
          }}
        </div>
      </div>
    </section>
    <!-- 空投奖励 -->
    <section class="reward">
      <div class="node-reward">
        <div class="title">{{ node.total }}</div>
        <div class="node-value">
          {{
            $gbUtils.formatNumber(
              $gbUtils.formatThreeBalance(dashBoard.totalNodeReward) || 0
            ) || 0
          }}
        </div>
        <div class="item">
          <div class="node">
            <SvgIcon iconClass="bigPoint" class="point-icon"></SvgIcon>
            <span>{{ dashBoard.superNodeCount || 0 }}</span>
          </div>
          <div class="last-reward">
            <div class="title">{{ node.last }}</div>
            <div class="value">
              {{
                $gbUtils.formatNumber(
                  $gbUtils.formatThreeBalance(
                    dashBoard.lastTotalSuperNodeReward
                  ) || 0
                ) || 0
              }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="node">
            <SvgIcon iconClass="smallPoint" class="point-icon" />
            <span>{{ dashBoard.normalNodeCount || 0 }}</span>
          </div>
          <div class="last-reward">
            <div class="title">{{ node.last }}</div>
            <div class="value">
              {{
                $gbUtils.formatNumber(
                  $gbUtils.formatThreeBalance(
                    dashBoard.lastTotalNormalNodeReward
                  ) || 0
                ) || 0
              }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 合约 -->
    <section class="contract">
      <div class="item">
        <div class="name">{{ contract.meb }}</div>
        <div
          class="value"
          data-clipboard-action="copy"
          data-clipboard-text="0x7268b479eb7ce8d1b37ef1ffc3b82d7383a1162d"
          @click="handleToCopyAddress"
        >
          <span
            @click="handleToChain('0x7268b479eb7ce8d1b37ef1ffc3b82d7383a1162d')"
            >0x7268b479eb7ce8d1b37ef1ffc3b82d7383a1162d</span
          ><img
            src="@/static/img/twins/copy.png"
            alt=""
            @click="handleToCopyAddress"
          />
        </div>
      </div>
      <div class="item">
        <div class="name">{{ contract.stake }}</div>
        <div
          class="value"
          data-clipboard-action="copy"
          data-clipboard-text="0x9ed0064f96196130b41156ac49cc59d4f5086340"
          @click="handleToCopyAddress"
        >
          <span
            @click="handleToChain('0x9ed0064f96196130b41156ac49cc59d4f5086340')"
            >0x9ed0064f96196130b41156ac49cc59d4f5086340</span
          ><img src="@/static/img/twins/copy.png" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="name">{{ contract.airdrop }}</div>
        <div
          class="value"
          data-clipboard-action="copy"
          data-clipboard-text="0x20fade07db6bf8e45c252d7da5f3360c1e901946"
          @click="handleToCopyAddress"
        >
          <span
            @click="handleToChain('0x20fade07db6bf8e45c252d7da5f3360c1e901946')"
            >0x20fade07db6bf8e45c252d7da5f3360c1e901946</span
          ><img src="@/static/img/twins/copy.png" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="name">{{ contract.coin }}</div>
        <div
          class="value"
          data-clipboard-action="copy"
          data-clipboard-text="0xd2145811746f50508ddd0cc110c99add8fbd2d72"
        >
          <span
            @click="handleToChain('0xd2145811746f50508ddd0cc110c99add8fbd2d72')"
            >0xd2145811746f50508ddd0cc110c99add8fbd2d72</span
          ><img src="../../static/img/twins/copy.png" alt="" />
        </div>
      </div>
    </section>
    <!-- 列表 -->
    <section class="browers">
      <div class="search-wrap">{{ list.record }}</div>
      <div class="list">
        <div class="list-title">
          <div class="address">{{ list.Lp }}</div>
          <div class="address">{{ list.countdown }}</div>
          <div class="address">{{ list.hash }}</div>
        </div>
        <div class="line"></div>
        <div class="list-wrap">
          <van-list
            v-model="isLoading"
            :offset="50"
            loading-text=" "
            :finished="finished"
            @load="onReachBottom"
            ref="listRef"
          >
            <div v-for="item in rankList" :key="item.customerId">
              <div class="item">
                <div class="address">
                  {{ $gbUtils.formatThreeBalance(item.amount || 0) }}
                </div>
                <div class="address">{{ item.createTime }}</div>
                <div
                  class="address"
                  @click="handleToTx(item.txHash)"
                  v-if="item.txHash"
                >
                  <span>{{ item.txHash }}</span>
                </div>
                <div class="address" v-else></div>
              </div>
              <div class="line"></div>
            </div>
          </van-list>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import config from '@/config/app.config'
import Clipboard from 'clipboard'
import SvgIcon from '@/components/SvgIcon.vue'
import { queryDashBoard, queryUserRank } from '@/services/browers'
export default {
  name: 'Browers',
  components: {
    SvgIcon
  },
  data () {
    return {
      dashBoard: {},
      rankList: [],
      address: null,
      pageSize: 15,
      pageNumber: 1,
      total: 0,
      finished: false, // 加载结束
      refreshing: false, // 下拉刷新状态
      isLoading: false // 下拉刷新状态
    }
  },
  computed: {
    header () {
      return this.$t('browers.header')
    },
    node () {
      return this.$t('browers.node')
    },
    airdrop () {
      return this.$t('browers.airdrop')
    },
    contract () {
      return this.$t('browers.contract')
    },
    list () {
      return this.$t('browers.list')
    },
    level () {
      return this.$t('browers.level')
    }
  },
  methods: {
    init () {
      this.getDashBoard()
      this.getStakeList()
    },
    // 复制合约地址
    handleToCopyAddress () {
      const clipboard = new Clipboard('.value')
      clipboard.on('success', () => {
        return this.$toast('success')
      })
      clipboard.on('error', (error) => {
        console.log('error', error)
      })
    },
    // 查看交易
    handleToTx (address) {
      window.open(`${config.bscBrowser}/tx/${address}`)
    },
    // 查看地址
    handleToChain (address) {
      window.open(`${config.bscBrowser}/address/${address}`)
    },
    // 触底
    onReachBottom () {
      if (this.pageNumber * this.pageSize < this.total) {
        this.isLoading = true
        this.pageNumber++
        this.getStakeList()
      } else {
        this.isLoading = false
        return false
      }
    },
    // 复制地址
    handleCopyAddress () {
      const clipboard = new Clipboard('.address')
      clipboard.on('success', () => {
        return this.$toast('success')
      })
    },
    // 获取数据面板
    async getDashBoard () {
      const resp = await queryDashBoard()
      if (resp.code === 200) {
        this.dashBoard = resp.result
      }
    },
    // 获取质押列表
    async getStakeList () {
      const resp = await queryUserRank({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      })
      if (+resp.code !== 200) return
      this.total = resp.result.total
      resp.result.records.map(item => {
        item.createTime = this.$moment().format('X') - this.$moment(item.createTime).format('X')
        item.createTime = this.formatSeconds(item.createTime)
      })
      if (resp.code === 200) {
        this.rankList.push(...resp.result.records)
        this.isLoading = false
      }
      this.$refs.listRef.check()
    },
    // 时间处理
    formatSeconds (value) {
      var secs = parseInt(value)// 需要转换的时间秒
      var mins = 0// 分
      var hs = 0// 小时
      var days = 0// 天
      if (secs > 60) {
        mins = parseInt(secs / 60)
        secs = parseInt(secs % 60)
        if (mins > 60) {
          hs = parseInt(mins / 60)
          mins = parseInt(mins % 60)
          if (hs > 24) {
            // 大于24小时
            days = parseInt(hs / 24)
            hs = parseInt(hs % 24)
          }
        }
      }
      if (+value < 60) {
        return '' + secs + this.list.s + this.list.ago
      } else if (+value < 3600) {
        return '' + mins + this.list.m + this.list.ago
      } else if (+value < (3600 * 24)) {
        return '' + hs + this.list.h + mins + this.list.m + this.list.ago
      } else {
        if (+hs === 0) {
          return '' + days + this.list.day + mins + this.list.m + this.list.ago
        }
        return '' + days + this.list.day + hs + this.list.h + this.list.ago
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  padding-bottom: 80px;
  .title {
    color: #7b8fbb;
    font-size: 12px;
  }
  .header {
    box-sizing: border-box;
    height: 225px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    .item {
      width: 50%;
      min-height: 65px;
      .title {
        // padding-top: 10px;
        height: 20px;
      }
      .value {
        height: 20px;
        color: #2a2b33;
        font-weight: bold;
        font-size: 20px;
        // padding-top: 8px;
      }
      .percent {
        padding-top: 5px;
        font-size: 12px;
        color: #25e2b1;
      }
    }
    .item:nth-child(even) {
      text-align: right;
    }
  }
  .airdrop {
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 74px;
    margin-top: 10px;
    padding: 20px;
    line-height: 20px;
    .item {
      display: flex;
      font-size: 12px;
    }
  }
  .reward {
    box-sizing: border-box;
    height: 155px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
    .node-reward {
      padding-top: 5px;
      .node-value {
        font-weight: bold;
        color: #2a2b33;
        font-size: 20px;
        // padding-top: 8px;
        padding-bottom: 5px;
      }
      .value {
        font-weight: bold;
      }
      .item {
        display: flex;
        align-items: center;
        padding-top: 10px;
        .node {
          // width: 100px;
          display: flex;
          // width: 20%;
          align-items: center;
          // justify-content: ;
          padding-right: 20px;
          // padding-top: 8px;
          font-size: 12px;
          .point-icon {
            font-size: 16px;
          }
          span {
            padding: 0 8px;
          }
        }
        .last-reward {
          display: flex;
          // width: 50%;
          justify-content: space-between;
          align-items: center;
          .value {
            padding-left: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .contract {
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    font-size: 12px;
    .item {
      padding-bottom: 15px;
    }
    .name {
      padding-bottom: 5px;
      color: #7b8fbb;
    }
    .value {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    span {
      cursor: pointer;
      color: #4c5968;
      border-bottom: 1px solid #4c5968;
      letter-spacing: -0.3px;
    }
    img {
      padding-left: 10px;
    }
  }
  .browers {
    // max-height: calc(100vh - 120px);
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    .search-wrap {
      font-size: 14px;
    }
    .list {
      border-radius: 15px;
      // overflow-x: scroll;
      border: 1px solid #a85fff;
      margin-top: 10px;
      padding: 0 20px;
      box-sizing: border-box;
      .address {
        width: 25%;
      }
      .address:nth-child(2) {
        width: 20%;
        min-width: 100px;
        text-align: center;
      }
      .address:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // width: 30%;
        text-align: center;
      }
      .airdrop-title {
        // width: 730px;
        height: 20px;
        min-width: 100px;
      }
      .list-title {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        line-height: 44px;
        height: 44px;
        // justify-content: space-around;
      }
      .list-wrap {
        // width: 730px;
        min-height: 200px;
        max-height: calc(100vh - 250px);
        overflow-y: scroll;
        .item {
          display: flex;
          // justify-content: space-around;
          font-size: 12px;
          height: 44px;
          line-height: 44px;
        }
        .address:nth-child(2) {
          box-sizing: border-box;
          text-align: left;
          padding-left: 30px;
        }
        .address:last-child {
          span {
            color: #4c5968;
            border-bottom: 1px solid #4c5968;
          }
        }
        // align-items: center;
      }
    }
  }
  .line {
    height: 1px;
    background-color: #f1f1f1;
  }
  .panel {
    // margin-left: 30px;
    // width: 610px;
    // padding: 10px 20px;
    background-color: #f9f9f9;
    // box-sizing: border-box;
  }
}
</style>
<style lang="scss">
.pc-style {
  .browser-page {
    .list-wrap {
      max-height: calc(100vh - 300px) !important;
    }
  }
}
</style>
