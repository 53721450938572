import request from '@/utils/request'

// 浏览器数据看板
export const queryDashBoard = (params) => {
  return request('GET', '/app/summary/dash_board', params)
}

// 用户排名
export const queryUserRank = (params) => {
  return request('GET', '/app/summary/lp/log', params, true, true)
}

// 根据用户查询
export const queryUserByAddress = (params) => {
  return request('GET', '/app/summary/lp/contract', params)
}
